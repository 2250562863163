import Vue from 'vue'

// 注册全局过滤器，时间过滤器
Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)

  // 年月日
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  // 时分秒
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return `${y}-${m}-${d}`
})


Vue.filter('relativeTime', function (originVal) {
  const now = new Date();
  const date = new Date(originVal);
  const diff = now - date;
  const diffInMinutes = Math.floor(diff / (1000 * 60)); // 毫秒转分钟
  const diffInHours = Math.floor(diff / (1000 * 60 * 60)); // 毫秒转小时
  const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24)); // 毫秒转天

  if (diffInDays > 1) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  } else if (diffInDays === 1) {
    return `昨天 ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  } else if (diffInHours > 1) {
    return `${diffInHours}小时前`;
  } else if (diffInHours === 1) {
    return `1小时前`;
  } else if (diffInMinutes > 1) {
    return `${diffInMinutes}分钟前`;
  } else if (diffInMinutes === 1) {
    return `1分钟前`;
  } else {
    return `刚刚`;
  }
});
