import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

const NotFound = () => import('../views/NotFound/NotFound.vue')
const LoginView = () => import('../views/Login.vue')
const AdminWelcomeView = () => import('../views/Admin/AdminWelcome.vue')
const AdminPageView = () => import('../views/Admin/AdminPage.vue')
const LoginLog = () => import('../views/Admin/SystemLog/LoginLogView.vue')
const OperationLog = () => import('../views/Admin/SystemLog/OperationLogView.vue')
const UserCtrl = () => import('../views/Admin/PlatformCtrl/UserCtrl.vue')
const RoleCtrl = () => import('../views/Admin/PlatformCtrl/RoleCtrl.vue')
const MenuCtrl = () => import('../views/Admin/PlatformCtrl/MenuCtrl.vue')
const AheadApplication = () => import('../views/Application/QwenAheadLaw.vue')
const StumageApplication = () => import('../views/Application/QwenStudentMage.vue')
const StudentAppModule = () => import('../views/Module/StudentAppModule.vue')
const OAModule = () => import('../views/Module/OAModule.vue')

Vue.use(VueRouter)

// 非权限路由
const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/aheadapp',
    name: 'aheadapp',
    component: AheadApplication,
  },
  {
    path: '/stumageapp',
    name: 'stumadapp',
    component: StumageApplication,
  }
]

// 权限路由
const rightRoutes = [
  {
    path : '/adminpage',
    name : 'adminpage',
    redirect: '/adminWelcome',
    component: AdminPageView,
    children: [
      {
        path: '/adminWelcome',
        component: AdminWelcomeView,
      },
      {
        path: '/userctrl',
        component: UserCtrl,
      },
      {
        path: '/roleCtrl',
        component: RoleCtrl,
      },
      {
        path: '/menuctrl',
        component: MenuCtrl,
      },
      {
        path: '/loginLog',
        component: LoginLog
      },
      {
        path: '/operationLog',
        component: OperationLog
      },
      {
        path: '/oamodule',
        component: OAModule
      },
      {
        path: '/studentappmodule',
        component: StudentAppModule
      }
    ]
  }
]

// 404 页面路由
const notFoundRoute = {
  path: '/*',
  name: 'notFound',
  component: NotFound,
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  notFoundRoute,
})


// 解决重复点击相同路由报错问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}


//挂载路由导航守卫
router.beforeEach(async (to, from, next) => {
  // 登录页放行
  if (to.path === '/login') return next()
  

  // 应用页面，校验是否存在authtoken，如果没有则继续，有就直接放行
  if (to.path === '/aheadapp' || to.path === '/stumageapp') {
    // 获取authtoken
    const authtoken = to.query.authToken;
    // 如果authtoken没有携带，查看缓存中是否存在
    if (!authtoken) {
      // 获取缓存内的authtoken
      const storgeAuthToken = window.sessionStorage.getItem('authtoken')
      if(storgeAuthToken){
        // 存在，放行
        return next()
      }else{
        return next('/login')
      }
    }else{
      // authtoken存入缓存
      window.sessionStorage.setItem('authtoken',authtoken)
      return next()
    }
  }

  const tokenStr = window.sessionStorage.getItem('token')

  // token是否存在，不存在清除路由，以及跳转到登录页面
  if (!tokenStr) {
    store.commit('updateVerifyAuthStauts', false)

    for (const key in rightRoutes)
      router.addRoute({ name: rightRoutes[key].name, path: `/${new Date()}` })

    return next('/login')
  }

  // token存在但是内存中登录状态为 false
  if (tokenStr && store.state.verifyAuthStauts === false) {
    // 获取登录状态
    await store.dispatch('verifyAuth')
    // 通过判断获取的登录状态来进行渲染路由或者删除路由
    if (store.state.verifyAuthStauts) {
      // 渲染路由，放行
      for (const key in rightRoutes) router.addRoute(rightRoutes[key])
      return next(to)
    } else {
      // 删除路由，跳转到登录页面
      for (const key in rightRoutes)
        router.addRoute({ name: rightRoutes[key].name, path: `/${new Date()}` })
      console.error('非法跳转，请登录！')

      return next('/login')
    }
  } else if (tokenStr && store.state.verifyAuthStauts === true) {
    // 既有token，内存中登录状态为true，放行
    next()
  }
})

export default router
