import { instance,instanceWithoutToken } from '../request.js'

/*
 * @ author : 许良
 * @ description : 校验token是否有效
 * @ time :  2023/8/3 11：42
 */
export const verifyAuthApi = () => {
  return instance.post('/aliyunBailian/verifyAuthentication')
}

export const getVerifyCode = (data) => {
  return instanceWithoutToken.post('/aliyunBailian/getVerifyCode',data)
}

export const verifyAuthToken = (param) => {
  return instanceWithoutToken.get('/aliyunBailian/verifyAuthToken/'+param)
}

export const getAuthToken = () => {
  return instance.post('/aliyunBailian/getPlatformUserAuthToken')
}
