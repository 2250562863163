import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './plugins/element.js'
import './plugins/vant.js'

// 导入全局指令
import './utils/directive'
// 导入全局过滤器
import './utils/filter'

// 导入字体图标
import './assets/font/style.css'  

// 全局样式
import './assets/css/global.css'
import './assets/css/chatbot.css'  
import './assets/css/dynamic.css'
import 'element-ui/lib/theme-chalk/base.css';
import 'vant/lib/index.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)
import Fragment from 'vue-fragment'
// 导入axios
import axios from 'axios'

// eventBus 用于组件间通信
Vue.prototype.$bus = new Vue()

// 设定axios请求地址与token挂载
//axios.defaults.baseURL = 'http://36.134.81.43:8334/'
// axios.defaults.baseURL = 'http://localhost:7439/'
// axios.interceptors.request.use((config) => {
//   config.headers.token = window.sessionStorage.getItem('token')
//   return config
// })

Vue.prototype.$http = axios
Vue.use(Fragment.Plugin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
