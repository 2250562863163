import Vue from 'vue'

// 注册全局指令，在1秒内不难重复点击某个元素
Vue.directive('onceClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      setTimeout(() => el.blur(), 500)

      if (binding.value != null && typeof binding.value !== 'number')
        return console.error('指令onceClick的值必须是数字')

      if (el.style.pointerEvents === '') {
        el.style.pointerEvents = 'none'
        setTimeout(() => {
          el.style.pointerEvents = ''
        }, binding.value || 1000)
      }
    })
  },
})

// 注册全局指令，在1秒内不难重复双击击某个元素
Vue.directive('doubleClick', {
  inserted(el, binding) {
    el.addEventListener('dblclick', () => {
      setTimeout(() => el.blur(), 500)

      if (binding.value != null && typeof binding.value !== 'number')
        return console.error('指令onceClick的值必须是数字')

      if (el.style.pointerEvents === '') {
        el.style.pointerEvents = 'none'
        setTimeout(() => {
          el.style.pointerEvents = ''
        }, binding.value || 1000)
      }
    })
  },
})

// 注册全局指令，按钮点击完成之后失去焦点
Vue.directive('removeFocus', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      el.blur()
    })
  },
})
