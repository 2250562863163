import Vue from 'vue'
import {
	Button,
	Form,
	FormItem,
	Input,
	Message,
	Container,
	Header,
	Aside,
	Main,
	Menu,
	Submenu,
	MenuItemGroup,
	MenuItem,
	Breadcrumb,
	BreadcrumbItem,
	Card,
	Row,
	Col,
	Table,
	TableColumn,
	Switch,
	tooltip,
	pagination,
	Dialog,
	MessageBox,
	Avatar,
	Select,
	Option,
	Tree,
	Descriptions,
	DescriptionsItem,
	RadioGroup,
	Radio,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	Tabs,
	TabPane,
	Upload,
	Tag,
	Loading,
	Divider,
	timeline,
	TimelineItem,
	Checkbox,
	DatePicker,
	ColorPicker,
	CheckboxGroup,
	Badge,
	Empty,
	Popover,
	calendar,
	Transfer,
	Slider,
	Progress,
	Steps,
	Step,
	Autocomplete,
	Collapse,
	CollapseItem
} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Col)
Vue.use(Row)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(tooltip)
Vue.use(pagination)
Vue.use(Dialog)
Vue.use(Avatar)
Vue.use(Option)
Vue.use(Select)
Vue.use(Tree)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Upload)
Vue.use(Tag)
Vue.use(Loading)
Vue.use(Divider)
Vue.use(timeline)
Vue.use(TimelineItem)
Vue.use(Checkbox)
Vue.use(DatePicker)
Vue.use(ColorPicker)
Vue.use(CheckboxGroup)
Vue.use(Badge)
Vue.use(Empty)
Vue.use(Popover)
Vue.use(calendar)
Vue.use(Transfer)
Vue.use(Slider)
Vue.use(Progress)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Autocomplete)
Vue.use(Collapse)
Vue.use(CollapseItem)

//挂载弹窗提示
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$msgbox = MessageBox