import Vue from 'vue'
import Vuex from 'vuex'
import { verifyAuthApi } from '@/request/verify/verifyAuth'

Vue.use(Vuex)


// 全局状态
const state = {
  // 登入用户名，默认为游客
  username: '游客',
  // 当前选择月份，需要初始化为当前月份
  mouth: '',
  // 是否是合法登录状态
  verifyAuthStauts: false,
}


const mutations = {
  // 更新用户登入名
  updateUsername(state, val) {
    state.username = val
  },
  // 更新月份
  updateMouth(state, val) {
    state.mouth = val
  },
  // 更新登录状态
  updateVerifyAuthStauts(state, val) {
    state.verifyAuthStauts = val
  },
}


const actions = {
  // 验证本地token是否合法
  async verifyAuth({ commit }) {
    const res = await verifyAuthApi()
    if (res.code == 200) commit('updateVerifyAuthStauts', true)
    else commit('updateVerifyAuthStauts', false)
  },
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {},
})
